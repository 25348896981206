




























































































































































import { defineComponent } from "@vue/composition-api";
import moment from "moment";
import { mapActions as mapPiniaActions, mapState as mapPiniaState } from "pinia";
import { mapActions } from "vuex";
import type { LesUitgebreid } from "@/lib/backend/coach.api";
import { useAppStore } from "@/pinia/app";
import { useLocationStore } from "@/pinia/location";
import { dagMaand, weekDag } from "@/utils/date";

type Data = {
	limits: number[];
	limit: number;
	page: number;
	loading: boolean;
	lessen: Array<LesUitgebreid & { date: moment.Moment; tijdslot_id: number }>;
	filters: Record<"tijd" | "locatie" | "zaal", string>;
};

export default defineComponent({
	data(): Data {
		return {
			limits: [25, 50, 100],
			limit: 25,
			page: 1,
			loading: false,
			lessen: [],
			filters: {
				tijd: "vandaag",
				locatie: "all",
				zaal: "all",
			},
		};
	},
	computed: {
		...mapPiniaState(useLocationStore, {
			location: "location",
			locations: "locationsSortedByName",
		}),
		...mapPiniaState(useAppStore, { occupancyFormat: "occupancyFormat" }),
		filteredTijdsloten(): Array<LesUitgebreid & { date: moment.Moment; tijdslot_id: number }> {
			if (!this.lessen.length) return [];
			const filteredTijdsloten = this.lessen
				.filter((les) => {
					return les.vestiging_naam === this.filters.locatie || this.filters.locatie === "all";
				})
				.filter((les) => {
					if (this.filters.tijd === "aankomend") {
						return moment().diff(les.date, "hours") < 0;
					} else if (this.filters.tijd === "afgelopen") {
						return moment().diff(les.date, "hours") > 0;
					} else if (this.filters.tijd === "vandaag") {
						return moment().isSame(les.date, "day");
					}
					return true;
				});
			return filteredTijdsloten;
		},
		limitedData(): Array<LesUitgebreid & { date: moment.Moment; tijdslot_id: number }> {
			if (!this.filteredTijdsloten) return [];
			const limitedData = this.filteredTijdsloten.filter((row, i) => {
				if (i >= (this.page - 1) * this.limit && i < this.page * this.limit) return true;
				return false;
			});
			if (!this.limit) return this.filteredTijdsloten || [];
			return limitedData;
		},
		pages(): number {
			if (!this.filteredTijdsloten || !this.filteredTijdsloten.length || !this.limit) return 0;
			const pages = Math.ceil(this.filteredTijdsloten.length / this.limit);
			return pages;
		},
	},
	watch: {
		filters: {
			handler() {
				this.page = 1;
			},
			deep: true,
		},
		// reset zaal to all on location change
		"filters.locatie": {
			handler(newVal, oldVal) {
				if (newVal !== oldVal) {
					this.filters.zaal = "all";
				}
			},
		},
	},
	async created() {
		this.loading = true;

		await this.initLocation();

		this.populateTijdsloten();

		if (this.location) {
			this.filters.locatie = this.location?.naam;
		}

		this.loading = false;
	},
	methods: {
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapActions("tijdsloten", ["getTijdslotenActueel"]),
		async populateTijdsloten() {
			this.loading = true;

			const data = await this.getTijdslotenActueel();

			// @ts-expect-error Not typed
			this.lessen = data.map((les) => {
				les.date = moment(`${les.datum} ${les.tijd}`, "DD-MM-YYYY H:mm");
				return les;
			});

			this.loading = false;
		},
		resetFilters() {
			Object.keys(this.filters).forEach((key) => {
				if (key === "tijd" || key === "locatie" || key === "zaal") this.filters[key] = "all";
			});
			this.filters.tijd = "vandaag";
		},
		paginate(num: number) {
			this.page = num;
		},
		weekDag,
		dagMaand,
	},
});
